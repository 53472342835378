import '@hotwired/turbo-rails'
import "tocbot/dist/tocbot"
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import ahoy from 'ahoy.js';

window.ahoy = ahoy;

window.Alpine = Alpine;

Alpine.plugin(collapse);

Alpine.start();

document.addEventListener("turbo:load", () => {

  if(!document.querySelector('.toc-container')) {
    return;
  }

  tocbot.init({
    // Where to render the table of contents.
    tocSelector: '.toc-container',
    // Where to grab the headings to build the table of contents.
    contentSelector: '.article-content',
    // Which headings to grab inside of the contentSelector element.
    headingSelector: 'h1, h2, h3',
    // For headings inside relative or absolute positioned containers within content.
    hasInnerContainers: false,
    headingsOffset: 56,
    scrollSmoothOffset: -56,
  });
})


window.onscroll = function () {
  var className = 'inverted';
  var scrollTrigger = 60;
  const headerEl = document.getElementsByClassName('header')[0];
  if (window.scrollY >= scrollTrigger) {
    headerEl.classList.add(className);
  } else {
    headerEl.classList.remove(className);
  }
};

(function () {
  var scrollPositions = {};

  addEventListener('turbo:before-render', function () {
    document.querySelectorAll('[data-turbo-permanent]').forEach( (element) => {
      scrollPositions[element.id] = element.scrollTop;
    });
  });

  addEventListener('turbo:render', function () {
    document.querySelectorAll('[data-turbo-permanent]').forEach( (element) => {
      element.scrollTop = scrollPositions[element.id];
    });
  });

  // Scroll to active sidebar link when do a hard refresh (CMD+R or F5)
  addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.sidebar-link.active').forEach((element) => {
      element.scrollIntoView({ block: 'nearest', inline: 'start' });
    })
  });

})();
